import React, { Component } from 'react';
import axios from 'axios';
// import moment from 'moment';
import { REFRACTION_DATA, LOADER_RED, EMR_APP_ENV, SAVE_OPTHAL_DATA } from '../../utils/constant';
import AsideLeft from '../../components/aside/asideleft';
import { Pagination, Modal, DatePicker, notification, Space, Form, Input, Button, Checkbox } from 'antd';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';
import AppHelpers from '../../utils/AppHelpers';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';

export default class opthal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refractionDefaultData: null,
            refractionOtherDetails: {
                id: null,
                re_dct: '',
                le_dct: '',
                right_lens: '',
                left_lens: '',
                bino_vn: '',
                refraction_remarks: '',
                refraction_prescription: false
            },
            ophthalIop: {
                id: null,
                right_undil: null,
                right_dil: null,
                right_corr: null,
                left_undil: null,
                left_dil: null,
                left_corr: null,
                iop_prescription: false
            },
            hospital_id: props.hospital_id,
            reference_id: props.referenceId,
            patient: props.patient,
            patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
            referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
            doctorId: LS_SERVICE.get("staff_id"),
            speech: {
                identifier: null,
                state: false
            },
            accordions: [],
            allOphthalSectionFields: [],
            ss_configuration: [],
            consult_type: ''
        }
        this.updateRefractionData = [];
        this.opthalFormRef = React.createRef();
        this.textareaRefs = {};
    }

    componentDidMount() {
        // const { match: { params } } = this.props;
        const ss_configuration = LS_SERVICE.get('ss_configuration');
        const patient_full_data = LS_SERVICE.get('call_patient_detail');
        this.setState({
            ss_configuration: ss_configuration,
            consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
        })
        const { hospital_id, patient, reference_id } = this.state;
        const patientDet = {
            hospital_id,
            patient_id: patient.patient_id,
            qms_token_id: reference_id
        }
        this.setState({
            hospital_id,
            patient_id: patient.patient_id,
            reference_id,
            patient: patientDet
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
                this.getRefractionDefaultData();
            }
        });
        // this.navigateToPage = this.navigateToPage.bind(this);

        this.subscription = speechTextService.status().subscribe(data => {

            const validOtherRefractionIdentifiers = [
                're_dct', 'le_dct', 'left_lens',
                'right_lens', 'bino_vn', 'refraction_remarks'
            ];

            if (this.state.speech.state && validOtherRefractionIdentifiers.includes(this.state.speech.identifier)) {
                this.setState(prevState => ({
                    refractionOtherDetails: {
                        ...prevState.refractionOtherDetails,
                        [this.state.speech.identifier]: data.text,
                    },
                }));
            }

            if (this.state.speech.state && this.state.allOphthalSectionFields.includes(this.state.speech.identifier)) {
                this.setState((prevState) => ({
                    accordions: prevState.accordions.map((accordion) => {
                        const updatedFields = accordion.fields.map((field) => {
                            if (field.hasOwnProperty(this.state.speech.identifier)) {
                                return {
                                    ...field,
                                    [this.state.speech.identifier]: data.text,
                                };
                            }
                            return field;
                        });

                        return {
                            ...accordion,
                            fields: updatedFields,
                        };
                    }),
                }));
            }


            if (this.state.speech.identifier) {
                document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
            }

        });
    }

    componentWillUnmount() {
        this.saveOpthalData();
        this?.subscription?.unsubscribe();
    }

    initSpeech(info) {
        let initialText = '';
        let { refractionOtherDetails, accordions, allOphthalSectionFields } = this.state;
        const keyMapping = {
            re_dct: 're_dct',
            le_dct: 'le_dct',
            left_lens: 'left_lens',
            right_lens: 'right_lens',
            bino_vn: 'bino_vn',
            refraction_remarks: 'refraction_remarks'
        };

        if (info.identifier in keyMapping) {
            initialText = refractionOtherDetails[keyMapping[info.identifier]];
        }

        if (info.type && info.type == 'ophthal_section' && allOphthalSectionFields.includes(info.identifier)) {
            const accordion = accordions.find(item => item.name == info.section_name);
            if (!accordion) {
                //do nothing
            } else {
                for (const field of accordion.fields) {
                    if (info.identifier in field) {
                        initialText = field[info.identifier];
                        break;
                    }
                }
            }
        }


        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    getRefractionDefaultData = () => {
        try {
            let { patientId, referenceId } = this.state;
            axios.post(REFRACTION_DATA, {
                patient_id: patientId,
                reference_id: referenceId
            }).then(response => {
                if (response && response.data && response.data.data) {
                    this.setState({
                        refractionDefaultData: response.data.data,
                        refractionOtherDetails: {
                            ...response.data.data.refractionOtherDetails,
                            refraction_prescription: response.data.data.refractionOtherDetails.refraction_prescription == '1' ? true : false
                        },
                        ophthalIop: {
                            ...response.data.data.ophthalIOP,
                            iop_prescription: response.data.data.ophthalIOP.iop_prescription == '1' ? true : false,
                        },
                        accordions: response.data.data.ophthalSections ? response.data.data.ophthalSections : [],
                        allOphthalSectionFields: response.data.data.allOphthalSectionFields ? response.data.data.allOphthalSectionFields : []
                    });
                }
            });
        }
        catch (err) {
            console.log(`get-refraction-default-data-error:`, err);
        }
    }

    autoHeight = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    toCamelCase = (str) => {
        return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    };

    saveOpthalData = async () => {
        let { patientId, referenceId, doctorId, refractionOtherDetails, ophthalIop, accordions } = this.state;
        let ophthalSectionData = {};
        let ophthalSectionDataId = null;
        if (accordions.length > 0) {
            accordions.forEach(section => {
                ophthalSectionDataId = section.ophthal_section_field_data_id;
                Object.keys(section).forEach(secKey => {
                    if (secKey.endsWith("_normal") && section.show_normal_finding == 'Y') {
                        const camelSecKey = this.toCamelCase(secKey);
                        ophthalSectionData[camelSecKey] = section[secKey] == true ? '1' : '0';
                    }
                    if (secKey.endsWith("_prescription")) {
                        const camelSecKey = this.toCamelCase(secKey);
                        ophthalSectionData[camelSecKey] = section[secKey] == true ? '1' : '0';
                    }
                });
                section.fields.forEach(field => {
                    Object.keys(field).forEach(key => {
                        if (key.endsWith("_left") || key.endsWith("_right")) {
                            const camelKey = this.toCamelCase(key);
                            ophthalSectionData[camelKey] = field[key] || null;
                        }
                    });
                });
            });
        }

        let data = {
            type: 'all',
            refraction_data: this.updateRefractionData,
            refraction_other_details: refractionOtherDetails,
            ophthal_section_data: ophthalSectionData,
            ophthal_iop: ophthalIop,
            patient_id: patientId,
            reference_id: referenceId,
            doctor_id: doctorId,
            ophthal_section_data_id: ophthalSectionDataId
        }
        await axios.post(SAVE_OPTHAL_DATA, data).then(res => {
            if (res.data && res.data && res.data.status) {
                this.getRefractionDefaultData();
            }
        })
    }

    capitalizeFirstChar = (str) => {
        if (typeof str !== "string" || str.trim() === "") {
            return str; // Return as-is for non-strings or empty values
        }
        
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    onChangeValue = (e, data) => {

        let Value = this.capitalizeFirstChar(e.target.value);
        let { refractionDefaultData, ophthalIop } = this.state;

        if (data.type == 'refraction') {
            let allData = [...refractionDefaultData['RIGHT'], ...refractionDefaultData['LEFT']];
            let findObj = this.updateRefractionData.find(item => item.opthal_refraction_id == data.opthal_refraction_id);
            if (findObj) {
                findObj[data.colName] = e.target.value;
            } else {
                let upObj = allData.find(item => item.opthal_refraction_id == data.opthal_refraction_id);
                if (upObj) {
                    upObj[data.colName] = e.target.value;
                    this.updateRefractionData.push(upObj);
                }
            }
            // if (data != null) {
            //     refractionDefaultData[data.key][data.colName] = e.target.value;
            //     this.setState({
            //         refractionDefaultData
            //     });
            // }
        }

        if (data.type == 'refraction_other_details') {
            this.setState((prevState) => ({
                refractionOtherDetails: {
                    ...prevState.refractionOtherDetails,
                    [data.colName]: Value ? Value : "",
                },
            }));
        }

        if (data.type == 'ophthal_iop') {
                this.setState((prevState) => ({
                    ophthalIop: {
                        ...prevState.ophthalIop,
                        [data.colName]: e.target.value ? e.target.value : null,
                    },
                }));
        }

        if (data.type === 'ophthal_section') {
            this.setState((prevState) => ({
                accordions: prevState.accordions.map(accordion => {
                    if (accordion.name == data.accordionName) {
                        return {
                            ...accordion,
                            fields: accordion.fields.map(field => {
                                if (field.name === data.fieldName) {
                                    return {
                                        ...field,
                                        [data.colName]: Value ? Value : "",
                                    };
                                }
                                return field;
                            }),
                        };
                    }
                    return accordion;
                })
            }));
        }

    }

    onClickCheckbox = (e, data) => {
        e.stopPropagation();
        let { refractionOtherDetails, ophthalIop } = this.state;
        const { name, checked } = e.target;
        if (name == 'refraction_prescription') {
            this.setState((prevState) => ({
                refractionOtherDetails: {
                    ...refractionOtherDetails,
                    refraction_prescription: checked
                }
            }))
        }
        if (name == 'iop_prescription') {
            this.setState((prevState) => ({
                ophthalIop: {
                    ...ophthalIop,
                    iop_prescription: checked
                }
            }))
        }
        if(data.type == 'accordion'){
            this.setState((prevState) => ({
                accordions: prevState.accordions.map(accordion => {
                    if (accordion.name == data.section_name) {
                        return {
                            ...accordion,
                            [name]: checked,
                            fields: accordion.fields.map(field => {
                                Object.keys(field).forEach(item=>{
                                    if((item.includes('left') || item.includes('right')) && !name.includes('prescription')){
                                        field[item] = checked ? 'Normal' : ""
                                    }
                                })
                                return field;
                            }),
                        };
                    }
                    return accordion;
                })
            }));
        } 
    }

    setTextareaRef = (fieldName, side, element) => {
        if (side == 'refraction' && element) {
            this.textareaRefs[`${fieldName}`] = element;
            element.style.height = "auto";
            element.style.height = `${element.scrollHeight}px`;
        }
        if (element) {
            this.textareaRefs[`${fieldName}_${side}`] = element;
            element.style.height = "auto";
            element.style.height = `${element.scrollHeight}px`;
        }
    };

    adjustAllTextareas = () => {
        setTimeout(() => {
            Object.values(this.textareaRefs).forEach((textarea) => {
                if (textarea) {
                    textarea.style.height = "auto";
                    textarea.style.height = `${textarea.scrollHeight}px`;
                }
            });
        }, 50);
    };

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.accordions) !== JSON.stringify(this.state.accordions)) {
            this.adjustAllTextareas();
        }
    }

    adjustRefractionTextareas = () => {
        let allTextAreas = ['le_dct', 're_dct', 'bino_vn', 'refraction_remarks', 'left_lens', 'right_lens'];
        allTextAreas.forEach(textarea => {
            document.querySelector("#" + textarea).style.height = document.querySelector("#" + textarea).scrollHeight + "px";
        })
    }

    render() {
        const { patient, accordions, refractionDefaultData, refractionOtherDetails, ophthalIop, ss_configuration, consult_type } = this.state;
        return (
            <>
                <div
                    className="emrfrm"
                >
                    {
                        ss_configuration.some(o => (o?.id === 17 && o.sub_items.some(p => ((p?.id === 35 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ?
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className="accordion opthal_accordion" id="accordionRefraction">
                                        <div className="card opthal-card">
                                            <div className="card-headers opthal-card-header" id="headingRefraction">
                                                <h2 className="mb-0">
                                                    <button
                                                        onClick={this.adjustAllTextareas}
                                                        class="btn btn-block text-left arrow float-right mb-0 collapsed d-flex justify-content-between"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseRefraction"
                                                        aria-expanded="false"
                                                        aria-controls="collapseRefraction">
                                                        <div className='row w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='col-4'>
                                                                REFRACTION
                                                            </div>
                                                            <div className='col-4'>
                                                            </div>
                                                            <div className="col-4 d-flex justify-content-end">
                                                                <div class="custom-control custom-checkbox-md mr-4" style={{ marginTop: '-16px' }}>
                                                                <input type="checkbox"
                                                                id={`refraction_prescription`}
                                                                name={`refraction_prescription`}
                                                                checked={refractionOtherDetails['refraction_prescription']}
                                                                onChange={(e) => this.onClickCheckbox(e, {
                                                                    section_name: 'refraction_prescription',
                                                                    type: 'refraction'
                                                                })}
                                                                className="custom-control-input  cursor-pointer" />
                                                            <label onClick={(e) => e.stopPropagation()} className="custom-control-label cursor-pointer text-secondary" style={{ fontSize: '0.8rem', paddingTop: '3px' }} htmlFor="refraction_prescription">Print With Prescription</label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseRefraction" class="collapse show" aria-labelledby="headingRefraction" data-parent="#accordionRefraction">
                                                <div className="card-body opthal-card-body">
                                                    {
                                                        refractionDefaultData != null ?
                                                            <div className="d-flex justify-content-between">
                                                                <table style={{ width: '52%' }} className='table refraction-table-opthal '>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ border: "0px", background: 'transparent' }} colSpan={1}>
                                                                            </th>
                                                                            <th colSpan={6} className='text-center'>
                                                                                RIGHT
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['UCVA_RIGHT_COLUMNS']).map(columnName => (
                                                                                columnName != 'id' && columnName != 'opthal_refraction_id' ? columnName == "name" ?
                                                                                    <td style={{ background: 'transparent', border: 'none' }}>
                                                                                    </td> :
                                                                                    <td colSpan={2}>{columnName}</td>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['UCVA_RIGHT_COLUMNS']).map(columnName => (
                                                                                columnName != 'id' && columnName != 'opthal_refraction_id' ?
                                                                                    columnName == "name" ?
                                                                                        <td>
                                                                                            {
                                                                                                refractionDefaultData['UCVA_RIGHT_COLUMNS'][columnName]
                                                                                            }
                                                                                        </td>
                                                                                        :
                                                                                        <td colSpan={2}>
                                                                                            <div className="form-group mb-0" id="dval">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    step="any"
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={refractionDefaultData['UCVA_RIGHT_COLUMNS'][columnName]}
                                                                                                    onChange={(e) => this.onChangeValue(e, {
                                                                                                        id: refractionDefaultData['UCVA_RIGHT_COLUMNS']['id'],
                                                                                                        opthal_refraction_id: refractionDefaultData['UCVA_RIGHT_COLUMNS']['opthal_refraction_id'],
                                                                                                        type: 'refraction',
                                                                                                        colName: columnName,
                                                                                                        key: 'UCVA_RIGHT_COLUMNS'
                                                                                                    })}
                                                                                                    id="dval"
                                                                                                    name="dval"
                                                                                                    className="form-control"
                                                                                                    maxLength="50"
                                                                                                    placeholder=""
                                                                                                    style={{ border: 'none', textAlign: 'center' }}
                                                                                                    // onKeyDown={(e) => {
                                                                                                    //     if (e.key.toLowerCase() === 'e') {
                                                                                                    //         e.preventDefault();
                                                                                                    //     }
                                                                                                    // }} 
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['CVA_RIGHT_COLUMNS'][0]).map(cvaColName => (
                                                                                cvaColName != 'id' && cvaColName != 'opthal_refraction_id' ?
                                                                                    cvaColName == 'name' ?
                                                                                        <th style={{ background: '#fff' }}></th>
                                                                                        :
                                                                                        <th className='text-center'>{cvaColName}</th>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {refractionDefaultData['CVA_RIGHT_COLUMNS'].map(cvaObj => (
                                                                            <tr>
                                                                                {Object.keys(cvaObj).map(cvaColName => (
                                                                                    cvaColName != 'id' && cvaColName != 'opthal_refraction_id' ?
                                                                                        cvaColName == 'name' ?
                                                                                            <td>
                                                                                                {cvaObj[cvaColName]}
                                                                                            </td> :
                                                                                            <td>
                                                                                                <div className="form-group mb-0" id="sphpg_pright">
                                                                                                    <input defaultValue={cvaObj[cvaColName]} id="sphpg_pright" name="sphpg_pright" 
                                                                                                    autoComplete="off"
                                                                                                    type="text"
                                                                                                        step="any"
                                                                                                        className="form-control " maxLength="50" placeholder="" style={{ border: 'none', textAlign: 'center' }}
                                                                                                        onChange={(e) => this.onChangeValue(e, {
                                                                                                            id: cvaObj['id'],
                                                                                                            opthal_refraction_id: cvaObj['opthal_refraction_id'],
                                                                                                            type: 'refraction',
                                                                                                            colName: cvaColName,
                                                                                                            key: 'CVA_RIGHT_COLUMNS'
                                                                                                        })}
                                                                                                        // onKeyDown={(e) => {
                                                                                                        //     if (e.key.toLowerCase() === 'e') {
                                                                                                        //         e.preventDefault();
                                                                                                        //     }
                                                                                                        // }} 
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                        : null
                                                                                ))}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <table style={{ width: '46%' }} className='table refraction-table-opthal '>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={6} className='text-center'>
                                                                                Left
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['UCVA_LEFT_COLUMNS']).map(columnName => (
                                                                                columnName != 'id' && columnName != 'opthal_refraction_id' && columnName != 'name' ?
                                                                                    <td colSpan={2}>{columnName}</td>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['UCVA_LEFT_COLUMNS']).map(columnName => (
                                                                                columnName != "id" && columnName != 'opthal_refraction_id' && columnName != 'name' ?
                                                                                    <td colSpan={2}>
                                                                                        <div className="form-group mb-0" id="dval">
                                                                                            <input
                                                                                                defaultValue={refractionDefaultData['UCVA_LEFT_COLUMNS'][columnName]}
                                                                                                onChange={(e) => this.onChangeValue(e, {
                                                                                                    id: refractionDefaultData['UCVA_LEFT_COLUMNS']['id'],
                                                                                                    opthal_refraction_id: refractionDefaultData['UCVA_LEFT_COLUMNS']['opthal_refraction_id'],
                                                                                                    type: 'refraction',
                                                                                                    colName: columnName,
                                                                                                    key: 'UCVA_LEFT_COLUMNS'
                                                                                                })}
                                                                                                id="dval"
                                                                                                name="dval"
                                                                                                type="text"
                                                                                                autoComplete="off"
                                                                                                step="any"
                                                                                                className="form-control "
                                                                                                maxLength="50"
                                                                                                placeholder=""
                                                                                                style={{ border: 'none', textAlign: 'center' }}
                                                                                                // onKeyDown={(e) => {
                                                                                                //     if (e.key.toLowerCase() === 'e') {
                                                                                                //         e.preventDefault();
                                                                                                //     }
                                                                                                // }} 
                                                                                                />
                                                                                        </div>
                                                                                    </td>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            {Object.keys(refractionDefaultData['CVA_LEFT_COLUMNS'][0]).map(cvaColName => (
                                                                                cvaColName != 'id' && cvaColName != 'opthal_refraction_id' && cvaColName != 'name' ?
                                                                                    <th className='text-center'>{cvaColName}</th>
                                                                                    : null
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {refractionDefaultData['CVA_LEFT_COLUMNS'].map(cvaObj => (
                                                                            <tr>
                                                                                {Object.keys(cvaObj).map(cvaColName => (
                                                                                    cvaColName != 'id' && cvaColName != 'opthal_refraction_id' && cvaColName != 'name' ?
                                                                                        <td>
                                                                                            <div className="form-group mb-0" id="sphpg_pright">
                                                                                                <input
                                                                                                    defaultValue={cvaObj[cvaColName]}
                                                                                                    onChange={(e) => this.onChangeValue(e, {
                                                                                                        id: cvaObj['id'],
                                                                                                        opthal_refraction_id: cvaObj['opthal_refraction_id'],
                                                                                                        type: 'refraction',
                                                                                                        colName: cvaColName,
                                                                                                        key: 'CVA_LEFT_COLUMNS'
                                                                                                    })}
                                                                                                    id="sphpg_pright"
                                                                                                    name="sphpg_pright"
                                                                                                    type="text"
                                                                                                    autoComplete="off"
                                                                                                    step="any"
                                                                                                    className="form-control"
                                                                                                    maxLength="50"
                                                                                                    placeholder=""
                                                                                                    style={{ border: 'none', textAlign: 'center' }} 
                                                                                                    // onKeyDown={(e) => {
                                                                                                    //     if (e.key.toLowerCase() === 'e') {
                                                                                                    //         e.preventDefault();
                                                                                                    //     }
                                                                                                    // }} 
                                                                                                    />
                                                                                            </div>
                                                                                        </td>
                                                                                        : null
                                                                                ))}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div> : null
                                                    }
                                                    <div className='row mt-3'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['le_dct'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 'le_dct' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 'le_dct' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('le_dct', "refraction", el)}
                                                                            value={refractionOtherDetails['le_dct']}
                                                                            className="form-control speech-field"
                                                                            placeholder="LE DCT"
                                                                            name="le_dct"
                                                                            id='le_dct'
                                                                            rows="1"
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "le_dct"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="le_dct">LE DCT</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['bino_vn'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 'bino_vn' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 'bino_vn' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('bino_vn', "refraction", el)}

                                                                            value={refractionOtherDetails['bino_vn']}
                                                                            className="form-control speech-field"
                                                                            placeholder="Bino VN"
                                                                            name="bino_vn"
                                                                            id='bino_vn'
                                                                            rows="1"
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "bino_vn"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="bino_vn">Bino VN</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['re_dct'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 're_dct' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 're_dct' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('re_dct', "refraction", el)}

                                                                            value={refractionOtherDetails['re_dct']}
                                                                            className="form-control speech-field"
                                                                            placeholder="RE DCT"
                                                                            name="re_dct"
                                                                            rows="1"
                                                                            id='re_dct'
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "re_dct"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="re_dct">RE DCT</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['left_lens'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 'left_lens' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 'left_lens' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('left_lens', "refraction", el)}

                                                                            value={refractionOtherDetails['left_lens']}
                                                                            className="form-control speech-field"
                                                                            placeholder="Left-Type of lens"
                                                                            name="left_lens"
                                                                            id='left_lens'
                                                                            rows="1"
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "left_lens"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="left_lens">Left-Type of lens</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['right_lens'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 'right_lens' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 'right_lens' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('right_lens', "refraction", el)}

                                                                            value={refractionOtherDetails['right_lens']}
                                                                            className="form-control speech-field"
                                                                            placeholder="Right-Type of lens"
                                                                            name="right_lens"
                                                                            id='right_lens'
                                                                            rows="1"
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "right_lens"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="right_lens">Right-Type of lens</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-12'>
                                                                    <div className={`form-group ant-mic-wrap micwrap ${refractionOtherDetails['refraction_remarks'] !== "" ? "hasdata" : ""}`}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.initSpeech({ identifier: 'refraction_remarks' });
                                                                            }}
                                                                            className={
                                                                                this.state.speech.identifier === 'refraction_remarks' && this.state.speech.state
                                                                                    ? 'mic subjective-mic on mute'
                                                                                    : 'mic subjective-mic'
                                                                            }
                                                                        ></a>
                                                                        <textarea
                                                                            ref={(el) => this.setTextareaRef('refraction_remarks', "refraction", el)}

                                                                            value={refractionOtherDetails['refraction_remarks']}
                                                                            className="form-control speech-field"
                                                                            placeholder="Remarks"
                                                                            name="refraction_remarks"
                                                                            id='refraction_remarks'
                                                                            rows="1"
                                                                            onInput={(e) => this.autoHeight(e)}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: "refraction_other_details",
                                                                                colName: "refraction_remarks"
                                                                            })}
                                                                            autoComplete="off"
                                                                        ></textarea>
                                                                        <label htmlFor="refraction_remarks">Remarks</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        ss_configuration.some(o => (o?.id === 17 && o.sub_items.some(p => ((p?.id === 36 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ?
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <div className="accordion opthal_accordion" id="accordionIOP">
                                        <div className="card opthal-card">
                                            <div className="card-headers opthal-card-header" id="headingIOP">
                                                <h2 className="mb-0">
                                                <button
                                                        // onClick={this.adjustAllTextareas}
                                                        class="btn btn-block text-left arrow float-right mb-0 collapsed d-flex justify-content-between"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseIOP"
                                                        aria-expanded="false"
                                                        aria-controls="collapseIOP">
                                                        <div className='row w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='col-4'>
                                                                IOP (mm Hg)
                                                            </div>
                                                            <div className='col-4'>
                                                            </div>
                                                            <div className="col-4 d-flex justify-content-end">
                                                                <div class="custom-control custom-checkbox-md mr-4" style={{ marginTop: '-16px' }}>
                                                                    <input type="checkbox"
                                                                        id={`iop_prescription`}
                                                                        name={`iop_prescription`}
                                                                        checked={ophthalIop['iop_prescription']}
                                                                        onChange={(e) => this.onClickCheckbox(e, {
                                                                            section_name: 'iop_prescription',
                                                                            type: 'refraction'
                                                                        })}
                                                                        className="custom-control-input  cursor-pointer" />
                                                                    <label onClick={(e) => e.stopPropagation()} className="custom-control-label cursor-pointer text-secondary" style={{ fontSize: '0.8rem', paddingTop: '3px' }} htmlFor="iop_prescription">Print With Prescription</label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    
                                                </h2>
                                            </div>

                                            <div id="collapseIOP" class="collapse show" aria-labelledby="headingIOP" data-parent="#accordionIOP">
                                                <div className="card-body opthal-card-body d-flex justify-content-between">
                                                    <table className='table refraction-table-opthal'>
                                                        <thead style={{ backgroundColor: '#efefef', WebkitPrintColorAdjust: 'exact' }}>
                                                            <tr>
                                                                <th colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>RIGHT</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>UNDIL</td>
                                                                <td>DIL</td>
                                                                <td>CORR</td>
                                                            </tr>
                                                            <tr style={{ fontWeight: '500' }}>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="right_undil">
                                                                        <input
                                                                            type='text'
                                                                            id="right_undil"
                                                                            name="right_undil"
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['right_undil']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'right_undil'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }}                                              autoComplete="off"          
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="right_dil">
                                                                        <input
                                                                            id="right_dil"
                                                                            name="right_dil"
                                                                            type='text'
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['right_dil']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'right_dil'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }} 
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="right_corr">
                                                                        <input
                                                                            id="right_corr"
                                                                            name="right_corr"
                                                                            type='text'
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['right_corr']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'right_corr'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }} 
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table className='table refraction-table-opthal'>
                                                        <thead style={{ backgroundColor: '#efefef', WebkitPrintColorAdjust: 'exact' }}>
                                                            <tr>
                                                                <th colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>LEFT</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>UNDIL</td>
                                                                <td>DIL</td>
                                                                <td>CORR</td>
                                                            </tr>
                                                            <tr style={{ fontWeight: '500' }}>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="left_undil">
                                                                        <input
                                                                            id="left_undil"
                                                                            name="left_undil"
                                                                            type='text'
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['left_undil']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'left_undil'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }} 
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="left_dil">
                                                                        <input
                                                                            id="left_dil"
                                                                            name="left_dil"
                                                                            type='text'
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['left_dil']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'left_dil'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }} 
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td width="33.33%" style={{ padding: '0.2rem' }}>
                                                                    <div className="form-group mb-0" id="left_corr">
                                                                        <input
                                                                            id="left_corr"
                                                                            name="left_corr"
                                                                            type='text'
                                                                            className="form-control "
                                                                            maxLength="50"
                                                                            placeholder="Enter"
                                                                            style={{ border: 'none', textAlign: 'center' }}
                                                                            value={ophthalIop['left_corr']}
                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                type: 'ophthal_iop',
                                                                                colName: 'left_corr'
                                                                            })}
                                                                            // onKeyDown={(e) => {
                                                                            //     if (e.key.toLowerCase() === 'e') {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }} 
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                    {
                        accordions && accordions.length > 0 ?
                            accordions.map((accordion, accordionIndex) => {
                                return (
                                    ss_configuration.some(o => (o?.id === 17 && o.sub_items.some(p => ((p?.id === accordion.config_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                        ?
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                <div key={accordionIndex} className="accordion opthal_accordion" id={`accordion${accordionIndex}`}>
                                                    <div className="card opthal-card">
                                                        <div className="card-headers opthal-card-header" id={`heading${accordionIndex}`}>
                                                            <h2 className="mb-0">
                                                                <button className="btn btn-block text-left arrow float-right mb-0 collapsed"
                                                                    onClick={this.adjustAllTextareas}
                                                                    type="button" data-toggle="collapse" data-target={`#collapse${accordionIndex}`} aria-expanded="true" aria-controls={`collapse${accordionIndex}`}>
                                                                    <div className="row w-100 d-flex justify-content-between align-items-center">
                                                                        <div className='col-4'>{accordion.display_name}</div>
                                                                        {accordion.show_normal_finding == 'Y' ?
                                                                            <div className='col-4'>
                                                                                <div style={{ top: '-8px' }} className="custom-control custom-checkbox-md">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`${accordion.name}_normal`}
                                                                                        name={`${accordion.name}_normal`}
                                                                                        className="custom-control-input  cursor-pointer"
                                                                                        checked={accordion[`${accordion.name}_normal`]}
                                                                                        onClick={(e) => this.onClickCheckbox(e, {
                                                                                            section_name: accordion.name,
                                                                                            type: 'accordion'
                                                                                        })}
                                                                                    />
                                                                                    <label onClick={(e) => e.stopPropagation()} style={{ fontSize: "0.8rem", paddingTop: '3px' }} className="custom-control-label cursor-pointer" htmlFor={`${accordion.name}_normal`}>Normal Findings for All Examinations</label>
                                                                                </div>
                                                                            </div> :
                                                                            <div className='col-4'></div>
                                                                       }

                                                                        <div className='col-4 d-flex justify-content-end'> <div style={{ top: '-8px' }} className="custom-control custom-checkbox-md mr-4">
                                                                            <input
                                                                                style={{ left: '0px', top: '10px' }}
                                                                                type="checkbox"
                                                                                id={`${accordion.name}_prescription`}
                                                                                name={`${accordion.name}_prescription`}
                                                                                className="custom-control-input  cursor-pointer"
                                                                                checked={accordion[`${accordion.name}_prescription`]}
                                                                                onChange={(e) => this.onClickCheckbox(e, {
                                                                                    section_name: accordion.name,
                                                                                    type: 'accordion'
                                                                                })}
                                                                            />
                                                                            <label onClick={(e) => e.stopPropagation()} style={{ fontSize: "0.8rem", paddingTop: '3px' }} className="custom-control-label cursor-pointer text-secondary" htmlFor={`${accordion.name}_prescription`}>Print With Prescription</label>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                        </div>

                                                        <div id={`collapse${accordionIndex}`} className={`collapse ${accordion.config_id !== 40 && accordion.config_id !== 41 ? 'show' : ''}`}  aria-labelledby={`heading${accordionIndex}`} data-parent={`#accordion${accordionIndex}`}>
                                                            <div className="card-body opthal-card-body">
                                                                {accordion.fields.map((field, fieldIndex) => {
                                                                    const isFirstSection = fieldIndex === 0;
                                                                    return (
                                                                        <div key={fieldIndex}>
                                                                            {/* <div className={`row ${isFirstSection ? 'mt-0' : 'mt-3'} `}>
                                                                                <h6 className="col-12 page-titlse mb-0">{field.display_name}</h6>
                                                                            </div> */}
                                                                            <div className='row mt-3'>

                                                                                <div className='col-2'>
                                                                                    <h6 className="page-titlse mb-0">{field.display_name}</h6>
                                                                                </div>

                                                                                <div className='col-5'>
                                                                                    <div className={`form-group ant-mic-wrap micwrap mb-0 ${field[`${field.name}_left`] !== "" ? "hasdata" : ""}`}>
                                                                                        <a
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.initSpeech({ identifier: `${field.name}_left`, type: "ophthal_section", section_name: accordion.name });
                                                                                            }}
                                                                                            className={
                                                                                                this.state.speech.identifier === `${field.name}_left` && this.state.speech.state
                                                                                                    ? 'mic subjective-mic on mute'
                                                                                                    : 'mic subjective-mic'
                                                                                            }
                                                                                        ></a>
                                                                                        <textarea
                                                                                            className="form-control speech-field"
                                                                                            ref={(el) => this.setTextareaRef(field.name, "left", el)}
                                                                                            id={`${field.name}_left`}
                                                                                            name={`${field.name}_left`}
                                                                                            placeholder="Enter"
                                                                                            rows="1"
                                                                                            onInput={this.autoHeight}
                                                                                            value={field[`${field.name}_left`]}
                                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                                type: 'ophthal_section',
                                                                                                accordionName: accordion.name,
                                                                                                fieldName: field.name,
                                                                                                colName: `${field.name}_left`
                                                                                            })}
                                                                                            autoComplete="off"
                                                                                        ></textarea>
                                                                                        <label htmlFor={`${field.name}_left`}>Left</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='col-5'>
                                                                                    <div className={`form-group ant-mic-wrap micwrap mb-0 ${field[`${field.name}_right`] !== "" ? "hasdata" : ""}`}>
                                                                                        <a
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.initSpeech({ identifier: `${field.name}_right`, type: "ophthal_section", section_name: accordion.name });
                                                                                            }}
                                                                                            className={
                                                                                                this.state.speech.identifier === `${field.name}_right` && this.state.speech.state
                                                                                                    ? 'mic subjective-mic on mute'
                                                                                                    : 'mic subjective-mic'
                                                                                            }
                                                                                        ></a>
                                                                                        <textarea
                                                                                            className="form-control speech-field"
                                                                                            ref={(el) => this.setTextareaRef(field.name, "right", el)}
                                                                                            id={`${field.name}_right`}
                                                                                            name={`${field.name}_right`}
                                                                                            placeholder="Enter"
                                                                                            rows="1"
                                                                                            onInput={this.autoHeight}
                                                                                            value={field[`${field.name}_right`]}
                                                                                            onChange={(e) => this.onChangeValue(e, {
                                                                                                type: 'ophthal_section',
                                                                                                accordionName: accordion.name,
                                                                                                fieldName: field.name,
                                                                                                colName: `${field.name}_right`
                                                                                            })}
                                                                                            autoComplete="off"
                                                                                        ></textarea>
                                                                                        <label htmlFor={`${field.name}_right`}>Right</label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                )

                            })
                            : null
                    }
                </div>
            </>
        )
    }
}
