import React, { Component } from "react";
import axios from "axios";
import Axios from "axios";
import qs from "qs";
import { Modal, Button, Select, Spin,notification, Form } from "antd";

import MedicineForm from "../../components/drugs/medicine-form";
import MedicineTable from "../../components/drugs/medicine-table";
import TestForm from "../../components/tests/test-form";
import TestTable from "../../components/tests/test-table";
import InstructionsForm from "../../components/instructions/instructions-form";
import InstructionTable from "../../components/instructions/instructions-table";
import SupportingMaterial from '../../components/supporting-material/supporting-material-form';
import SupportingMaterialTable from '../../components/supporting-material/supporting-material-table';

import LS_SERVICE from "../../utils/localStorage";
import {
    jQueryLabels,
    AntSelectLabels,
    FixedCta,
} from "../../utils/jQueryLabels";
import {
    LOADER_RED,
    LOADER,
    ORDERSET_CREATE,
    VIEW_ORDERSET,
    DRUGS_CONSTANT,
    LAB_TEST_CONSTANTS,
    ALL_SPECIALITY_AGENT_LIST,
    ALL_DOCTOR_AGENT_LIST,
    USERTYPES,
    HOSPITAL_AGENT_LIST,
    SPECIALITY_AGENT_LIST,
    DOCTOR_AGENT_LIST,
    ORDERSET_SEARCH,
    ORDERSET_CHANGE_MAPPING,
    ALL_CIMS_INTERACTION,
    ALL_CIMS_INTERACTION_EXISTS,
    HOSPITAL_DOCTOR_LIST_ORDERSET
} from "../../utils/constant";

const { Option } = Select;

class saveOrderSet extends Component {
    constructor(props) {
        var defaultHospital;
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            defaultHospital = LS_SERVICE.get("admin_hospital_id") ? LS_SERVICE.get("admin_hospital_id") : undefined;
        }
        super(props);
        this.state = {
            spin_loading: false,
            medicineFlag: 'orderset',
            disableConfirmBtn: true,
            istemplateErrorVisible: false,
            showAssessmentModal: false,
            showMedicineModal: false,
            medicineFlag: 'orderset',
            ordersetList: [],
            showTestModal: false,
            showInstructionModal: false,
            showSupportingModal: false,
            dataLoaded: false,
            showtapper: true,
            alertMessage: "",
            OrdersetView: [
                {
                    constants: [],
                    orderSetDrugData: [],
                    orderSetInstruction: [],
                    orderSetTestData: [],
                },
            ],
            primaryDetailEdit: false,
            copybuttonchk: true,
            editData: null,
            hospital_id: defaultHospital,
            editIndex: -1,
            constants: [],
            orderSetName: "",
            HospitalDefaultValue: defaultHospital,
            SpecialityDefaultValue: [],
            DoctorDefaultValue: [],
            OwnerDefaultValue: [],
            assessmentData: [],
            addedAssessmentList: [],
            drugsData: [],
            addedDrugsList: [],
            testData: [],
            addedTestList: [],
            instructionsData: [],
            allSupportingMaterial: [],
            supportingMaterial: [],
            actionText: "",
            deleteConfirmModal: false,
            deleteMessage: "",
            deleteActionType: null,
            deleteObjId: null,
            orderSetId: "",
            idisNaN: true,
            pagename: "Create Order Set",
            name: "",
            isEdit: "",
            isHospitalHaveMaster: false,
            isHospitalHaveMasterVisible: false,
            cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
            employeeList: [],
            service_selected: [],
            doctor_ids: [],
            doctor_selected: []
        };
        this.childMedicine = React.createRef();
        this.childTest = React.createRef();
    }

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        LS_SERVICE.set('master_hospital_id', null);
        jQueryLabels();
        FixedCta();
        AntSelectLabels();
        this.setState({
            dataLoaded: false
        })
        this.loadData();
        await this.handleSerachDoctorList('');
        const arrRoute = this.props.location.pathname.split("/");
        const templateId = arrRoute[arrRoute.length - 1];
        const isEdit = arrRoute[arrRoute.length - 2];
        if(!isEdit)
        {
          this.state.doctor_selected.push(parseInt(LS_SERVICE.get('staff_id')));
        }
        await this.setState({ idisNaN: isNaN(templateId), isEdit: isEdit }, async () => {
            if (!isNaN(templateId)) {
                AntSelectLabels();
                if(this.props.location.copyProps){
                    this.setState({
                        orderSetId: "",
                        pagename: "Copy Order Set",
                        orderSetName: "",
                    });

                }else{
                    this.setState({
                        orderSetId: templateId,
                        pagename: "Edit Order Set",
                        orderSetName: this.props.history.location.state?.template?.orderSetName,
                    });
                }
                await this.OrderOnView(templateId);
            } else {
                this.setState({ pagename: "Create Order Set" });
            }
        });
        await this.hospitalList();
        await this.specialityList();
        await this.doctorList();
        /* this.setState({ 
          dataLoaded: true
        }) */
        this.ordersetList();
    }

    handleSerachDoctorList = search => {
        const doctor_id = LS_SERVICE.get("staff_id");
        const PARAMS = { search : search, hospital_id: LS_SERVICE.get("FACILITY_ID"), doctor_id: doctor_id }
        Axios.post(HOSPITAL_DOCTOR_LIST_ORDERSET, qs.stringify(PARAMS))
            .then(res => {
                this.setState({ employeeList: res.data.status ? res.data.data.results : [] });
            })
            .catch(err => {
                this.setState({ employeeList: [] });
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            FixedCta();
        }, 100);
    }

    // Load contants data for Drug & test
    loadData = () => {
        let { constants } = this.state;
        const drugsConstantsPromise = Axios.get(DRUGS_CONSTANT);
        const testConstantsPromise = Axios.get(LAB_TEST_CONSTANTS);

        Axios.all([drugsConstantsPromise, testConstantsPromise]).then(
            Axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];

                if (responseOne.data.hasOwnProperty("data") === true) {
                    constants = responseOne.data.data;
                }

                if (responseTwo.data.hasOwnProperty("data") === true) {
                    constants["radiologyViews"] = responseTwo.data.data;
                }

                this.setState({
                    dataLoaded: true,
                    constants,
                });
            })
        );
    };

    // Show Orderset Error Model
    showTemplateErrorModal = () => {
        this.setState({ istemplateErrorVisible: true });
    };

    // Orderset Error Model - Click OK
    handleTemplateErrorOk = () => {
        this.setState({
            istemplateErrorVisible: false,
            disableConfirmBtn: false,
        });
    };

    // Orderset Error Model - Click Cancel
    handleTemplateErrorCancel = () => {
        this.setState({
            istemplateErrorVisible: false,
            disableConfirmBtn: false,
        });
    };

    handleHospitalMasterError = () => {
        this.setState({
            isHospitalHaveMasterVisible: false,
        });
    };

    // Change orderset name to Sentance case
    savename = async (e) => {
        await this.setState({
            orderSetName:
                e.target.value.charAt(0).toUpperCase() +
                e.target.value.slice(1),
            primaryDetailEdit: true,
        });
        this.checkSaveBtnStatus();
    };

    // Get hospital list
    hospitalList = async () => {
        let PARAMS = {};
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
            await Axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    hospitalList: res?.data?.data?.results ? res?.data?.data?.results : [],
                });
            });
        }
    };

    // Get speciality list
    specialityList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if (this.state.hospital_id == -1) {
            await axios.post(ALL_SPECIALITY_AGENT_LIST).then((res) => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else {
            if (this.state.hospital_id) {
                PARAMS = {
                    hospital_id: this.state.hospital_id
                };
                await Axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        specialityList: res.data.status ? res.data.data.results : [],
                    });
                });
            }
            else {
                this.setState({
                    specialityList: [],
                });
            }
        }

        var final_speciality = [];
        var speciality_array = this.state.specialityList
        if (speciality_array && speciality_array.length > 0) {
            var SpecialityDefaultValue = this.state.SpecialityDefaultValue;
            for (var sp of SpecialityDefaultValue) {
                if (speciality_array.some(el => el.speciality_id === sp)) {
                    final_speciality.push(sp)
                }
            }
        }
        await this.setState({
            speciality_id: final_speciality.join(),
            SpecialityDefaultValue: final_speciality,
        });
    };

    // Get doctor list
    doctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if (this.state.hospital_id == -1 && this.state.speciality_id) {
            PARAMS = {
                speciality_id: this.state.speciality_id
            }
            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                var final_doctor = [];
                for (var doc of res?.data?.data?.results) {
                    final_doctor.push(doc)
                }
                this.setState({
                    doctorList: final_doctor,
                });
            });
        }
        else if (this.state.hospital_id && this.state.speciality_id) {
            PARAMS = {
                hospital_id: this.state.hospital_id,
                speciality_id: this.state.speciality_id
            };

            await Axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    doctorList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else if (this.state.hospital_id && !this.state.speciality_id) {
            if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                if (this.state.hospital_id == -1) {
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
                else {
                    PARAMS = { hospital_id: this.state.hospital_id };
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
            }
            else {
                PARAMS = { hospital_id: this.state.hospital_id };
                await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        doctorList: res?.data?.data?.results || [],
                    });
                });
            }
        }

        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });
    };

    // Get orderset list
    ordersetList = (search) => {
        const PARAMS = { searchTerm: search };

        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            PARAMS.filter_hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
            PARAMS.hospital_master = LS_SERVICE.get('master_hospital_id') ? 'Y' : 'N';
        } else if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS.filter_hospital_id = LS_SERVICE.get("admin_hospital_id");
            PARAMS.hospital_master = LS_SERVICE.get('slot_hospital_master') ?? 'N';
        }
        Axios.post(
            ORDERSET_SEARCH,
            qs.stringify(PARAMS)
        ).then((res) => {
            this.setState({
                ordersetList: res.data.status ? res.data.data.orderset : [],
            });
        });
    };

    // Handle hospital change
    selectHospitalChange = async (val, props) => {
        let hospital_id = [];
        props.forEach((element) => {
            hospital_id.push(element.dataid.hospital_id);
        });

        if (this.state?.HospitalDefaultValue?.includes(-1) == false && hospital_id?.includes(-1)) {
            hospital_id = [-1]
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        else if (this.state?.HospitalDefaultValue?.includes(-1) && hospital_id?.includes(-1)) {
            var index_of_all = hospital_id.indexOf(-1);
            hospital_id.splice(index_of_all, 1);
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        else {
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        await this.setState({
            isHospitalHaveMaster: false,
            ordersetList: [],
            copybuttonchk: true,
        });
        let master_hospital_id = null;
        if (!hospital_id?.includes(-1)) {
            if (hospital_id.length > 1) {
                props.forEach(async (element) => {
                    if (element.own_master == 'Y') {
                        await this.setState({
                            isHospitalHaveMaster: true,
                            isHospitalHaveMasterVisible: true
                        });
                        return false;
                    }
                });
            } else if (hospital_id.length == 1 && props[0].own_master == 'Y') {
                master_hospital_id = props[0].dataid.hospital_id;
            }
        }
        if (LS_SERVICE.get('master_hospital_id') != master_hospital_id) {
            await this.setState({
                drugsData: [],
                addedDrugsList: [],
                testData: [],
                addedTestList: [],
            });
            this.childMedicine.current.Formclear();
            this.childTest.current.Formclear();
        }
        LS_SERVICE.set('master_hospital_id', master_hospital_id);
        this.ordersetList();

        await this.specialityList();
        var final_speciality = [];
        var speciality_array = this.state.specialityList
        if (speciality_array && speciality_array.length > 0) {
            var SpecialityDefaultValue = this.state.SpecialityDefaultValue;
            for (var sp of SpecialityDefaultValue) {
                if (speciality_array.some(el => el.speciality_id === sp)) {
                    final_speciality.push(sp)
                }
            }
        }
        await this.setState({
            speciality_id: final_speciality.join(),
            SpecialityDefaultValue: final_speciality,
        });

        await this.doctorList();
        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });

        this.checkSaveBtnStatus();
    };

    // Handle speciality change
    selectSpecialityChange = async (val, props) => {
        let speciality_id = [];
        props.forEach((element) => {
            speciality_id.push(element.dataid.speciality_id);
        });
        await this.setState({
            speciality_id: speciality_id.join(),
            SpecialityDefaultValue: speciality_id,
            primaryDetailEdit: true,
        });

        await this.doctorList();
        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });

        this.checkSaveBtnStatus();
    };

    // Handle doctor change
    selectDoctorChange = async (val, props) => {
        let doctor_id = [];
        props.forEach((element) => {
            doctor_id.push(element.dataid.staff_id);
        });

        await this.setState({
            doctor_id: doctor_id.join(),
            DoctorDefaultValue: doctor_id,
            primaryDetailEdit: true,
        });
        this.checkSaveBtnStatus();
    };

    // Handle owner change
    selectOwnerChange = async (val, props) => {
        let owner_id = [];
        props.forEach((element) => {
            owner_id.push(element.dataid.staff_id);
        });

        await this.setState({
            owner_id: owner_id.join(),
            OwnerDefaultValue: owner_id,
            primaryDetailEdit: true,
        });
        this.checkSaveBtnStatus();
    };

    // Handle copy from list change
    selectCopyFromChange = async (val, props) => {
        if (props?.value) {
            await this.setState({
                copybuttonchk: false,
                selectedOrderSet: props?.value,
            });
        } else {
            await this.setState({
                copybuttonchk: true,
            });
        }
    };

    // Handle copy from existing order set
    copyFromOrderSet = async () => {
        if (this.state.selectedOrderSet) {
            await this.OrderOnView(this.state.selectedOrderSet);
        }
        await this.setState({
            primaryDetailEdit: true,
        });
        this.checkSaveBtnStatus();
    };

    // Get all details of one order set (for edit purpose)
    OrderOnView = async (templateId) => {
        let PARAMS = {
            orderSetId: templateId,
        }
        PARAMS.store_id = LS_SERVICE.get("STORE_ID");
        PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
        PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

        await axios
            .post(
                VIEW_ORDERSET,
                qs.stringify(PARAMS)
            )

            .then(async (success) => {
                if (success.data.status) {
                    if(this.props.location.copyProps){
                        if(success.data.orderSetDrugData){
                          for (let index = 0; index < success.data.orderSetDrugData.length; index++) {
                            delete success.data.orderSetDrugData[index]['id'];
                            if(success.data.orderSetDrugData[index].additives){
                              for (let index1 = 0; index1 < success.data.orderSetDrugData[index].additives.length; index1++) {
                                delete success.data.orderSetDrugData[index].additives[index1]['id'];
                              }           
                            }
                          }
                        }
                        if(success.data.orderSetTestData){
                          for (let index = 0; index < success.data.orderSetTestData.length; index++) {
                            delete success.data.orderSetTestData[index]['id'];
                          }
                        }
                        if(success.data.orderSetInstruction){
                          for (let index = 0; index < success.data.orderSetInstruction.length; index++) {
                            delete success.data.orderSetInstruction[index]['id'];
                            delete success.data.orderSetInstruction[index]['orderSetId'];
                          }
                        }
                    }
                      
                    var instructionsTemp = success.data.orderSetInstruction;
                    var instructionsData = [];
                    let selected_doctors_list = success.data.doctorIds;
                    let selected_doctors_ids = [];
                    if(selected_doctors_list != null)
                    {
                      let selected_doctors_data = selected_doctors_list.split(',');
                      if(selected_doctors_data && selected_doctors_data.length)
                      {
                        selected_doctors_data.map((value, i) => {
                            selected_doctors_ids.push(parseInt(value));
                        });
                        console.log(selected_doctors_ids);
                      }
                    }
                    await this.setState({
                        doctor_selected: selected_doctors_ids,
                    });
                    instructionsTemp.map((v, k) => {
                        instructionsData.push({
                            id: v.instructionId,
                            instruction: v.instruction,
                        });
                    });
                    var supportingTemp = success.data.orderSetSupportingMaterial;
                    var supportingData = [];
                    supportingTemp.map(file => {
                        supportingData.push({
                            id: file.id,
                            fileName: file.fileName,
                            fileUrl: file.fileUrl
                        })
                    })
                    var addedDrugsList = [];
                    success.data.orderSetDrugData.map((v, k) => {
                        addedDrugsList.push(v.drug_id);
                    });
                    var addedTestList = [];
                    success.data.orderSetTestData.map((v, k) => {
                        addedTestList.push(v.test_id);
                    });
                    this.setState({
                        OrdersetView: success.data,
                        orderSetData: success.data.orderSetData,
                        drugsData: success.data.orderSetDrugData,
                        addedDrugsList,
                        testData: success.data.orderSetTestData,
                        addedTestList,
                        instructionsData: instructionsData,
                        supportingMaterial: supportingData
                    });

                    if (!this.state.selectedOrderSet) {
                        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
                            this.setState({
                                HospitalDefaultValue: parseInt(LS_SERVICE.get("admin_hospital_id")),
                                hospital_id: LS_SERVICE.get("admin_hospital_id"),
                            });
                        }
                        else {
                            var hospital_array = success?.data?.orderSetData?.hospitalId ? success?.data?.orderSetData?.hospitalId?.split(",").map((x) => +x) : [];
                            this.setState({
                                HospitalDefaultValue: hospital_array,
                                hospital_id: success.data.orderSetData.hospitalId,
                            });
                        }
                        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                            LS_SERVICE.set('master_hospital_id', success.data.orderSetData?.master_hospital_id != null ? parseInt(success.data.orderSetData?.master_hospital_id) : null);
                        }

                        var speciality_array = success?.data?.orderSetData?.specialityId ? success?.data?.orderSetData?.specialityId?.split(",").map((x) => +x) : [];
                        this.setState({
                            SpecialityDefaultValue: speciality_array,
                            speciality_id: success.data.orderSetData.specialityId,
                        });

                        var owner_array = success?.data?.orderSetData?.ownerId ? success?.data?.orderSetData?.ownerId.split(",").map((x) => +x) : [];
                        this.setState({
                            OwnerDefaultValue: owner_array,
                            owner_id: success.data.orderSetData.ownerId,
                        });

                        var doctor_array = success?.data?.orderSetData?.doctorId ? success?.data?.orderSetData?.doctorId?.split(",").map((x) => +x) : [];
                        this.setState({
                            DoctorDefaultValue: doctor_array,
                            doctor_id: success.data.orderSetData.doctorId,
                        });
                    }
                    await this.hospitalList();
                    await this.specialityList();
                    await this.doctorList();
                }
            })
            .catch((err) => console.log(err));
    };

    optionEmployeeLoad() {
        if (!this.state.employeeList || this.state.employeeList.length === 0) {
            return (<Option key={0} value={0}><span>No Result Found</span></Option>)
        }
        return Array.from(new Map(this.state.employeeList.map(obj => [obj.staff_id, obj])).values()).map(function (obj, i) {
            return (
                <Option
                    key={obj.staff_id}
                    value={obj.staff_id}
                    data_description={'Dr. ' + obj.first_name + ' (' + obj.speciality_name + ')'}
                >
                {'Dr. ' + obj.first_name + ' (' + obj.speciality_name + ')'}
                </Option>
            );
        }, this);        
    }

    // Update the added medicines data
    updateMedicineData = (data, showTaper) => {
        let drugsData = this.state.drugsData;



        let addedDrugsList = [];
        var index = data.i;
        if (index == -1) index = drugsData.length;
        drugsData[index] = data;


        addedDrugsList = drugsData.map((v, k) => {
            return v.drug_id;
        });
        this.setState(
            {
                drugsData,
                addedDrugsList,
                editData: null,
                editIndex: -1,
                primaryDetailEdit: true,
            },
            () => {

                this.checkSaveBtnStatus();
                if (!showTaper)
                    this.handleModalPopup(null, "showMedicineModal");
            }
        );
    };

    // Update the added tests data
    updateTestData = (data) => {
        let testData = this.state.testData;
        let addedTestList = [];
        var index = data.i;
        if (index == -1) index = testData.length;
        testData[index] = data;

        addedTestList = testData.map((v, k) => {
            return v.test_id;
        });

        this.setState(
            {
                testData,
                addedTestList,
                editData: null,
                editIndex: -1,
                primaryDetailEdit: true,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Update the added instructions data
    updateInstructionsData = (data) => {
        this.setState(
            {
                instructionsData: data,
                showInstructionModal: false,
                primaryDetailEdit: true,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    async checkDataIsTapper(index) {

        let data_is = this.state.drugsData;
        let index_is = parseInt(index);
        let index_length = parseInt(data_is?.length);

        let array_data = [];
        let array_ids = [];
        if (data_is?.length > 0) {
            for (let i = 0; i < data_is.length; i++) {
                if (array_ids.includes(parseInt(i)) == false) {
                    let id1 = data_is[i].drug_id;
                    array_data.push(data_is[i]);

                    for (let j = i + 1; j < data_is.length; j++) {
                        let id2 = data_is[j].drug_id;
                        if (id1 == id2) {
                            array_data.push(data_is[j]);
                            array_ids.push(parseInt(j));
                        }
                    }
                }
            }
        }
        // let finaldata = [...data_is]?.sort((a, b) => a.drug_id - b.drug_id);
        let finaldata = array_data;

        let on_samll = false;
        let on_big = false;
        let show_taper = false;
        if (index_is != 0) {
            if (finaldata[index_is - 1].drug_id == finaldata[index_is].drug_id) {
                on_samll = true
            }
        }
        if (index_is + 1 != index_length) {
            if (finaldata[index_is + 1].drug_id == finaldata[index_is].drug_id) {
                on_big = true
            }
        }
        if (on_samll || on_big) {
            show_taper = true;
        }
        console.log("show_taper", show_taper)
        return show_taper;
    }

    // populate the details on form for edit (medicine)
    handleEdit = async (data, i) => {
        let showtapper = await this.checkDataIsTapper(i);
        this.setState({
            editData: null,
            editIndex: -1,
            showtapper: showtapper
        });

        let array_data = [];
        let array_ids = [];
        if (this.state.drugsData?.length > 0) {
            for (let i = 0; i < this.state.drugsData.length; i++) {
                if (array_ids.includes(parseInt(i)) == false) {
                    let id1 = this.state.drugsData[i].drug_id;
                    array_data.push(this.state.drugsData[i]);

                    for (let j = i + 1; j < this.state.drugsData.length; j++) {
                        let id2 = this.state.drugsData[j].drug_id;
                        if (id1 == id2) {
                            array_data.push(this.state.drugsData[j]);
                            array_ids.push(parseInt(j));
                        }
                    }
                }
            }
        }
        let finaldata = array_data;
        //let finaldata = [...this.state.drugsData]?.sort((a, b) => a.drug_id - b.drug_id);
        this.setState(
            {
                actionText: "Edit",
                editData: finaldata[i],
                editIndex: i,
            },
            () => {
                const tabId = document.getElementById(
                    "create-medicine-details"
                );
                window.scrollTo({
                    top: tabId.offsetTop - 10,
                    behavior: "smooth",
                });
            }
        );
        AntSelectLabels();
    };

    // Handle all the popup models
    handleModalPopup = (
        e,
        popupName,
        data = null,
        actionType = "",
        objId = null,
        patientDocs
    ) => {
        if (e !== null) e.preventDefault();
        this.setState(
            {
                [popupName]: !this.state[popupName],
            },
            () => {
                const { viewDocumentModal } = this.state;

                if (viewDocumentModal) {
                    this.carouselRef.current.goTo(patientDocs);
                }

                this.setState(
                    {
                        actionText: "Add",
                        editData: null,
                        editIndex: -1,
                    },
                    () => {
                        const {
                            showVitalsModal,
                            showAssessmentModal,
                            showMedicineModal,
                            showInstructionModal,
                            showTestModal,
                            deleteConfirmModal,
                            showDrugAllergyModal,
                            showDrugPolyPharmacyModal,
                        } = this.state;
                        if (showVitalsModal) {
                            this.setState(
                                {
                                    V001: this.state.patientHeight,
                                    V002: this.state.patientWeight,
                                },
                                () => {
                                    this.handleBmiCalculate();
                                    AntSelectLabels();
                                }
                            );
                        }

                        if (showAssessmentModal) {
                            if (actionType === "editAssessment") {
                                this.setState({
                                    actionText: "Edit",
                                    editData: this.state.assessmentData[objId],
                                    editIndex: objId,
                                });
                                AntSelectLabels();
                            }
                        }
                        if (showMedicineModal) {
                            if (actionType === "editMedicines") {
                                this.setState({ actionText: "Edit" });
                            }
                            if (data !== null) {
                                this.setState({
                                    actionText: "Edit",
                                    editData: this.state.drugsData[objId],
                                    editIndex: objId,
                                });
                            }
                        }

                        if (showInstructionModal) {
                            // const { allInstructionsData } = this.state;
                            // this.setState({
                            //   isNewInstruction: false,
                            //   instructionOption: allInstructionsData
                            // });
                        }

                        if (deleteConfirmModal) {
                            // this.setState({ actionType, objId });
                            // For Assessment
                            if (actionType === "deleteAssessment") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete assessment?",
                                });
                            }

                            // For Medicines
                            if (actionType === "deleteMedicine") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete medicine?",
                                });
                            }

                            // For Tests
                            if (actionType === "deleteTest") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete test?",
                                });
                            }
                        }

                        if (showDrugAllergyModal) {
                            this.setState({
                                allergiesMessage: data.message,
                                deleteObjId:
                                    data.type == "all" ? data.deleteObj : objId,
                            });
                        }

                        if (showDrugPolyPharmacyModal) {
                            this.setState({
                                polyPharmacyMessage: data.message,
                                deleteObjId: data.type == "all" ? null : objId,
                            });
                        }
                    }
                );
            }
        );
    };

    // handle the confirm popup for delete for all (i.e. Are you sure you want to delete?)
    handleOnItemDelete = (e, actionType, objId) => {
        e.preventDefault();

        var stateVariable = null;
        var stateAddedVariable = null;
        var propKey = null;
        // For Assessment
        if (actionType === "deleteAssessment") {
            stateVariable = "assessmentData";
            stateAddedVariable = "addedAssessmentList";
            propKey = "diagnosis_id";
        }
        // For Medicine
        if (actionType === "deleteMedicine") {
            stateVariable = "drugsData";
            stateAddedVariable = "addedDrugsList";
            propKey = "drug_id";
        }
        // For Test
        if (actionType === "deleteTest") {
            stateVariable = "testData";
            stateAddedVariable = "addedTestList";
            propKey = "test_id";
        }

        let dataArray = this.state[stateVariable];
        if (actionType === "deleteMedicine") {

            let array_data = [];
            let array_ids = [];
            if (dataArray?.length > 0) {
                for (let i = 0; i < dataArray.length; i++) {
                    if (array_ids.includes(parseInt(i)) == false) {
                        let id1 = dataArray[i].drug_id;
                        array_data.push(dataArray[i]);

                        for (let j = i + 1; j < dataArray.length; j++) {
                            let id2 = dataArray[j].drug_id;
                            if (id1 == id2) {
                                array_data.push(dataArray[j]);
                                array_ids.push(parseInt(j));
                            }
                        }
                    }
                }
            }
            dataArray = [...array_data];
        }
        dataArray.splice(objId, 1);
        let addedArray = [];
        dataArray.map((v, k) => {
            addedArray.push(v[propKey]);
        });
        this.setState(
            {
                deleteConfirmModal: false,
                [stateVariable]: dataArray,
                [stateAddedVariable]: addedArray,
                primaryDetailEdit: true,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Decide to only update the mapping or save orderset fully
    orderSetDetailsUpdate = () => {
        // if(LS_SERVICE.get('user_type') == USERTYPES.doctor || LS_SERVICE.get('user_type') == USERTYPES.admin || this.state.primaryDetailEdit == true){
        this.saveOrderSet();
        // }
        // else{
        //   this.changeMapToHospital()
        // }
    };

    // Map selected templates to selected hospitals
    changeMapToHospital = async (e) => {
        await axios
            .post(
                ORDERSET_CHANGE_MAPPING,
                qs.stringify({
                    hospital_ids: this.state.hospital_id,
                    orderset_id: this.state.orderSetId,
                })
            )
            .then((success) => {
                this.props.history.push({
                    pathname: `/order-set-list`,
                });
            })
            .catch((err) => console.log(err));
    };

    // Create/Update new order set
    saveOrderSet = () => {
        this.setState({ disableConfirmBtn: true, spin_loading: true });

        let {
            orderSetId,
            orderSetName,
            drugsData,
            testData,
            instructionsData,
            supportingMaterial
        } = this.state;

        drugsData.map((v, k) => {
            delete drugsData[k]["previousDrug"];
            delete drugsData[k]["templateId"];
            delete drugsData[k]["i"];
            delete drugsData[k]["id"];
            delete drugsData[k]["prescription_drug_id"];
            delete drugsData[k]["drug_generic"];
            delete drugsData[k]["active"];
        });

        testData.map((v, k) => {
            delete testData[k]["templateId"];
            delete testData[k]["is_requistion"];
            delete testData[k]["i"];
            delete testData[k]["fav"];
        });

        var other_details = {};
        if (
            LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
            LS_SERVICE.get("user_type") == USERTYPES.admin
        ) {
            other_details = {
                hospital_id: this.state.hospital_id,
                doctor_id: this.state.doctor_id,
                owner_id: this.state.owner_id,
                speciality_id: this.state.speciality_id,
            };
        } else {
            other_details = {
                hospital_id: LS_SERVICE.get("slot_hospital_id"),
                doctor_id: this.state.doctor_selected,
                owner_id: LS_SERVICE.get("staff_id"),
                speciality_id: LS_SERVICE.get("speciality_id"),
                hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
            };
        }

        axios
            .post(
                ORDERSET_CREATE,
                qs.stringify({
                    orderSetName: orderSetName,
                    orderSetId: orderSetId,
                    drugsData: drugsData,
                    testsData: testData,
                    instruction: instructionsData,
                    supporting_material_data: supportingMaterial,
                    ...other_details,
                })
            )
            .then((success) => {
                if (success.data.status) {
                    this.setState({
                        spin_loading: false
                    });
                    this.props.history.push({
                        pathname: `/order-set-list`,
                    });
                } else {
                    this.setState({
                        spin_loading: false
                    });
                    this.setState(
                        {
                            // alertMessage: success.data.message,
                            alertMessage: orderSetName + " already exists . Kindly review the same or use a different name"
                        },
                        () => {
                            this.showTemplateErrorModal();
                        }
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    onChangeDoctor = async (val, props) => {
        let serviceItems = [];
        await this.setState({
            doctor_selected: val,
        });
        if (props && props.length) {
            props.map((obj, i) => {
                serviceItems.push({ "doctors_id": obj.value, "doctors_name": obj.data_description });
            });
        }
        await this.setState({
          doctor_ids: serviceItems
        }, () => { this.checkSaveBtnStatus() });
    }


    // Clear the edit form (medicine / tests)
    clearEdit = () => {
        this.setState({
            editData: null,
            editIndex: -1,
        });
    };

    // Enable / Disable submit button based on the status
    checkSaveBtnStatus = () => {
        const { orderSetName, drugsData, testData, isHospitalHaveMaster, doctor_selected } = this.state;
        var allowSave = true;
        if (!orderSetName || orderSetName == "") allowSave = false;
        if (!doctor_selected) allowSave = false;
        if (drugsData.length == 0 && testData.length == 0) allowSave = false;
        if (
            (LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
                LS_SERVICE.get("user_type") == USERTYPES.admin) &&
            (!this.state.hospital_id || this.state.hospital_id == "")
        )
            allowSave = false;
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin && isHospitalHaveMaster) {
            allowSave = false;
        }

        this.setState({ disableConfirmBtn: !allowSave });
    };

    getMaterialData = (materialData) => {
        this.setState({ allSupportingMaterial: materialData });

    }

    saveSupportingMaterial = async _ => {
        this.setState({ supportingMaterial: this.state.allSupportingMaterial.filter(file => file.selected) }, () => {
            this.checkSaveBtnStatus();
        });
        this.handleModalPopup(null, 'showSupportingModal');
    }

    handleAllInteractionCancel = () => { this.setState({ isModalAllInteractionVisible: false }); };
    handleAllInteractionOk = () => { this.setState({ isModalAllInteractionVisible: false }) };
    showModalInteraction = () => {

        let { addedDrugsList } = this.state
        if (typeof addedDrugsList == 'undefined' || !addedDrugsList || addedDrugsList.length === 0 || addedDrugsList === "") {
            addedDrugsList = 0;
        } else {
            addedDrugsList = addedDrugsList.join('-');
        }

        let addedAssessmentList = 0;
        let medicineScreen = 'careprotocol';
        let referenceId = LS_SERVICE.get("call_patient_qms_token_id");
        let StoreId = LS_SERVICE.get("staff_id") ? LS_SERVICE.get("staff_id") : 0;
        let FacilityId = LS_SERVICE.get("FACILITY_ID") ? LS_SERVICE.get("FACILITY_ID") : 0;
        let enterpriseId = LS_SERVICE.get("ENTERPRISE_ID") ? LS_SERVICE.get("ENTERPRISE_ID") : 0;
        let patientId = LS_SERVICE.get("call_patient_id") ? LS_SERVICE.get("call_patient_id") : 0;
        let referenceType = 0;
        let orederSetId = 0;
        let careProtoColId = 0;
        let showAllInteractionModal = false;

        if (this.state.cimsallow) {
            let drugIds = addedDrugsList;
            axios.get(ALL_CIMS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
                .then(success => {
                    if (success.data.interaction) {
                        showAllInteractionModal = true;
                        this.setState({ drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, isModalAllInteractionVisible: showAllInteractionModal });
                    }else {
                        notification.error({
                          message: `No interactions Found`,
                          placement: 'topRight'
                        })
                      }
                })
                .catch(err => console.log(err))
        } else {
            this.setState({ isModalAllInteractionVisible: false })
        }
    };

    render() {


        const { hospitalList, specialityList, doctorList, ordersetList, drugsData, addedDrugsList } =
            this.state;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="px-2" id="data-preloader">
                        <div className="row" id="data-preloader" style={this.state.dataLoaded ? { display: "none" } : null}>
                            <div className="col-12">
                                <div className="text-center">{LOADER_RED}</div>
                            </div>
                        </div>
                    </div>
                    <div className="px-2" id="main-page-container" style={!this.state.dataLoaded ? { display: "none" } : null} >
                        <div className="row mt-2">
                            <h1 className="col-12 page-title">
                                {this.state.pagename}
                            </h1>
                            <div className="col-12 emrfrm">
                                <div className="row">
                                    {
                                        LS_SERVICE.get("user_type") == USERTYPES.doctor
                                            ?
                                            (
                                                <>
                                                    <div className="col-12 my-2">
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                id="orderSet-name"
                                                                name="orderset_name"
                                                                className="form-control mandatory-field input-template"
                                                                onChange={this.savename}
                                                                value={this.state.orderSetName}
                                                                placeholder=" "
                                                                maxLength="80"
                                                            />
                                                            <label htmlFor="orderset_name">
                                                                Order Set Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 my-2">
                                                        <div
                                                            className="filldata form-group bg-white"
                                                            id="doctorList"
                                                        >
                                                        <Select style={{ width: 100 + '%' }}
                                                            id="doctors_id"
                                                            nae="doctors_id"
                                                            showSearch
                                                            mode="multiple"
                                                            value={this.state.doctor_selected}
                                                            onChange={this.onChangeDoctor}
                                                            optionFilterProp="children"
                                                            className="select-diagnosis w-100"
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toString().toLowerCase()) >= 0
                                                                );
                                                            }}
                                                            getPopupContainer={() =>
                                                                document.getElementById("doctorList")
                                                            }
                                                            >
                                                            {this.optionEmployeeLoad()}
                                                        </Select>
                                                        <label htmlFor="doctors_id">Employees</label>
                                                      </div>
                                                    </div>
                                                </>

                                            )
                                            :
                                            null
                                    }

                                    {
                                        LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin
                                            ?
                                            (
                                                <div className="col-12 mt-2">
                                                    <div className="row">
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <Select
                                                                    showSearch
                                                                    id="hospital_name"
                                                                    placeholder=" "
                                                                    name="hospital_name"
                                                                    disabled={LS_SERVICE.get("user_type") == USERTYPES.admin ? true : false}
                                                                    style={{
                                                                        width:
                                                                            100 + "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectHospitalChange
                                                                    }
                                                                    mode={
                                                                        LS_SERVICE.get(
                                                                            "user_type"
                                                                        ) ==
                                                                            USERTYPES.superadmin
                                                                            ? "multiple"
                                                                            : "single"
                                                                    }
                                                                    className=""
                                                                    allowClear
                                                                    defaultValue={
                                                                        this.state
                                                                            .HospitalDefaultValue
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            .HospitalDefaultValue
                                                                    }
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >= 0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        LS_SERVICE.get("user_type") == USERTYPES.superadmin
                                                                            ?
                                                                            <Option dataid={{ hospital_id: -1 }} value={-1} key={-1}>
                                                                                MHC Hospitals
                                                                            </Option>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.hospitalList && hospitalList !== undefined && hospitalList.length > 0
                                                                            ?
                                                                            hospitalList.map(
                                                                                (d) => (
                                                                                    <Option dataid={d} value={d.hospital_id} key={d.hospital_id} own_master={d.own_master}>
                                                                                        {d.name}
                                                                                    </Option>
                                                                                )
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </Select>
                                                                <label htmlFor="hospital_name">
                                                                    Hospital
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3 ">
                                                            <div className="form-group ">
                                                                <Select
                                                                    showSearch
                                                                    id="speciality_name"
                                                                    placeholder=" "
                                                                    name="speciality_name"
                                                                    style={{
                                                                        width:
                                                                            100 + "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectSpecialityChange
                                                                    }
                                                                    defaultValue={
                                                                        this.state
                                                                            .SpecialityDefaultValue
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            .SpecialityDefaultValue
                                                                    }
                                                                    className="drug-input Priority"
                                                                    mode="multiple"
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >= 0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .specialityList &&
                                                                        specialityList !==
                                                                        undefined &&
                                                                        specialityList.length >
                                                                        0
                                                                        ? specialityList.map(
                                                                            (d) => (
                                                                                <Option
                                                                                    dataid={
                                                                                        d
                                                                                    }
                                                                                    value={
                                                                                        d.speciality_id
                                                                                    }
                                                                                    key={
                                                                                        d.speciality_id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        d.name
                                                                                    }
                                                                                </Option>
                                                                            )
                                                                        )
                                                                        : null}
                                                                </Select>
                                                                <label htmlFor="speciality_name">
                                                                    Speciality
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <Select
                                                                    showSearch
                                                                    id="doctor_name"
                                                                    placeholder=" "
                                                                    name="doctor_name"
                                                                    style={{
                                                                        width:
                                                                            100 + "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectDoctorChange
                                                                    }
                                                                    className=""
                                                                    mode="multiple"
                                                                    value={
                                                                        this.state
                                                                            .DoctorDefaultValue
                                                                    }
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >= 0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .doctorList &&
                                                                        doctorList !==
                                                                        undefined &&
                                                                        doctorList.length >
                                                                        0
                                                                        ? doctorList.map(
                                                                            (d) => (
                                                                                <Option
                                                                                    dataid={
                                                                                        d
                                                                                    }
                                                                                    value={
                                                                                        d.staff_id
                                                                                    }
                                                                                    key={
                                                                                        d.staff_id
                                                                                    }
                                                                                >
                                                                                    Dr. {d.first_name} {d.last_name} {d.woodlands_mobile ? '(' + d.woodlands_mobile + ')' : null}
                                                                                </Option>
                                                                            )
                                                                        )
                                                                        : null}
                                                                </Select>
                                                                <label htmlFor="doctor_name">
                                                                    Doctor's Name
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group">
                                                                <Select
                                                                    showSearch
                                                                    id="owner_name"
                                                                    placeholder=" "
                                                                    name="owner_name"
                                                                    style={{
                                                                        width:
                                                                            100 + "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectOwnerChange
                                                                    }
                                                                    className=""
                                                                    mode="multiple"
                                                                    defaultValue={
                                                                        this.state
                                                                            .OwnerDefaultValue
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            .OwnerDefaultValue
                                                                    }
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >= 0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .doctorList &&
                                                                        doctorList !==
                                                                        undefined &&
                                                                        doctorList.length >
                                                                        0
                                                                        ? doctorList.map(
                                                                            (d) => (
                                                                                <Option
                                                                                    dataid={
                                                                                        d
                                                                                    }
                                                                                    value={
                                                                                        d.staff_id
                                                                                    }
                                                                                    key={
                                                                                        d.staff_id
                                                                                    }
                                                                                >
                                                                                    Dr. {d.first_name} {d.last_name}
                                                                                </Option>
                                                                            )
                                                                        )
                                                                        : null}
                                                                </Select>
                                                                <label htmlFor="owner_name">
                                                                    Owner
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {this.state.isEdit == "edit" ? (
                                                            <>
                                                                <div className="col-12 my-2">
                                                                    <div className="form-group hasdata">
                                                                        <input
                                                                            type="text"
                                                                            id="orderSet-name"
                                                                            name="orderset_name"
                                                                            className="form-control mandatory-field input-template"
                                                                            onChange={
                                                                                this
                                                                                    .savename
                                                                            }
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .orderSetName
                                                                            }
                                                                            placeholder=" "
                                                                            maxLength="80"
                                                                        />
                                                                        <label htmlFor="orderset_name">
                                                                            Order Set
                                                                            Name
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-12 col-md-6">
                                                                    <div className="form-group hasdata">
                                                                        <input
                                                                            type="text"
                                                                            id="orderSet-name"
                                                                            name="orderset_name"
                                                                            className="form-control mandatory-field input-template"
                                                                            onChange={
                                                                                this
                                                                                    .savename
                                                                            }
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .orderSetName
                                                                            }
                                                                            placeholder=" "
                                                                            maxLength="80"
                                                                        />
                                                                        <label htmlFor="orderset_name">
                                                                            Order Set
                                                                            Name
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <div className="form-group">
                                                                        <Select
                                                                            showSearch
                                                                            id="copy_from_list"
                                                                            placeholder=" "
                                                                            name="copy_from_list"
                                                                            onChange={
                                                                                this
                                                                                    .selectCopyFromChange
                                                                            }
                                                                            style={{
                                                                                width:
                                                                                    100 +
                                                                                    "%",
                                                                            }}
                                                                            className=""
                                                                            allowClear
                                                                            filterOption={(
                                                                                input,
                                                                                option
                                                                            ) => {
                                                                                return (
                                                                                    option.children
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                        .indexOf(
                                                                                            input
                                                                                                .toString()
                                                                                                .toLowerCase()
                                                                                        ) >=
                                                                                    0
                                                                                );
                                                                            }}
                                                                            notFoundContent={
                                                                                this
                                                                                    .state
                                                                                    .spinner ? (
                                                                                    <Spin size="small" />
                                                                                ) : (
                                                                                    <div>
                                                                                        No
                                                                                        Result
                                                                                        Found
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        >
                                                                            {this.state
                                                                                .ordersetList &&
                                                                                ordersetList !==
                                                                                undefined &&
                                                                                ordersetList.length >
                                                                                0
                                                                                ? ordersetList.map(
                                                                                    (
                                                                                        d
                                                                                    ) => (
                                                                                        ((LS_SERVICE.get("user_type") == USERTYPES.superadmin && d.own_master == 'N' && !LS_SERVICE.get('master_hospital_id')) || (LS_SERVICE.get("user_type") == USERTYPES.superadmin && LS_SERVICE.get('master_hospital_id')) || (LS_SERVICE.get("user_type") != USERTYPES.superadmin)) ?
                                                                                            <Option
                                                                                                dataid={
                                                                                                    d
                                                                                                }
                                                                                                value={
                                                                                                    d.orderSetId
                                                                                                }
                                                                                                key={
                                                                                                    d.orderSetId
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    d.orderSetName
                                                                                                }
                                                                                            </Option>
                                                                                            : null
                                                                                    )
                                                                                )
                                                                                : null}
                                                                        </Select>
                                                                        <label htmlFor="copy_from_list">
                                                                            Copy from
                                                                            list
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-1">
                                                                    <div className="float-right">
                                                                        <Button
                                                                            type="button"
                                                                            className="btn btn-primary px-4 small text-uppercase"
                                                                            id="drug-save"
                                                                            data-drug="-1"
                                                                            data-prescriptionid="-1"
                                                                            disabled={
                                                                                this
                                                                                    .state
                                                                                    .copybuttonchk
                                                                            }
                                                                            onClick={() =>
                                                                                this.copyFromOrderSet()
                                                                            }
                                                                        >
                                                                            Copy
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <MedicineForm
                                                ref={this.childMedicine}
                                                formType={"template"}
                                                actionText={
                                                    this.state.actionText
                                                }
                                                updateData={
                                                    this.updateMedicineData
                                                }
                                                editData={this.state.editData}
                                                autofocus={false}
                                                editIndex={this.state.editIndex}
                                                addedDrugsList={
                                                    this.state.addedDrugsList
                                                }
                                                medicineFlag={
                                                    this.state.medicineFlag
                                                }
                                                constants={this.state.constants}
                                                patient={this.state.patient}
                                                clearEdit={this.clearEdit}
                                                showtapper={this.state.showtapper}
                                                durationChange={true}
                                            />
                                            <div className="col-md-12 my-4">
                                                <MedicineTable
                                                    drugsData={
                                                        this.state.drugsData
                                                    }
                                                    viewType={"template"}
                                                    showAction={true}
                                                    showSelectCheck={false}
                                                    constants={
                                                        this.state.constants
                                                    }
                                                    handlePopUp={
                                                        this.handleModalPopup
                                                    }
                                                    handleEdit={this.handleEdit}
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <TestForm
                                                ref={this.childTest}
                                                formType={"template"}
                                                actionText={
                                                    this.state.actionText
                                                }
                                                updateData={this.updateTestData}
                                                editData={this.state.editData}
                                                autofocus={false}
                                                editIndex={this.state.editIndex}
                                                addedTestList={
                                                    this.state.addedTestList
                                                }
                                                constants={this.state.constants}
                                                clearEdit={this.clearEdit}
                                                hospital_id={this.state.hospital_id}
                                            />
                                            <div className="col-md-12 my-4">
                                                <TestTable
                                                    editAllowed="N"
                                                    testData={
                                                        this.state.testData
                                                    }
                                                    viewType={"template"}
                                                    showAction={true}
                                                    showSelectCheck={false}
                                                    constants={
                                                        this.state.constants
                                                    }
                                                    handlePopUp={
                                                        this.handleModalPopup
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <div className="col-12">
                                                <div className="float-left">
                                                    <h3 className="page-title mt-2">
                                                        Instructions
                                                    </h3>
                                                </div>
                                                <div className="text-right">
                                                    <a
                                                        href="#"
                                                        className="btn btn-outline-primary small"
                                                        onClick={(e) =>
                                                            this.handleModalPopup(
                                                                e,
                                                                "showInstructionModal"
                                                            )
                                                        }
                                                    >
                                                        + INSTRUCTIONS
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-5">
                                                <ul className="custom-list list-success">
                                                    <InstructionTable
                                                        instructionsData={
                                                            this.state
                                                                .instructionsData
                                                        }
                                                        viewType={"full"}
                                                        constants={
                                                            this.state.constants
                                                        }
                                                    />
                                                </ul>
                                            </div>
                                        </>
                                    ) : null}

                                    {/* {this.state.dataLoaded ? (
                                        <>
                                            <div className="col-12">
                                                <div className="float-left">
                                                    <h3 className="page-title">
                                                        Supporting Material
                                                    </h3>
                                                </div>
                                                <div className="text-right">
                                                    <a
                                                        href="#"
                                                        className="btn btn-outline-primary small"
                                                        onClick={(e) =>
                                                            this.handleModalPopup(
                                                                e,
                                                                "showSupportingModal"
                                                            )
                                                        }
                                                    >
                                                        + SUPPORTING MATERIAL
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-5 pb-5">
                                                <ul className="custom-list list-success">
                                                    <SupportingMaterialTable
                                                        supportingMaterialData={this.state.supportingMaterial}
                                                        viewType={'full'}
                                                        hospitalId={this.state.hospital_id}
                                                        constants={this.state.constants}
                                                    />
                                                </ul>
                                            </div>
                                        </>
                                    ) : null} */}

                                </div>
                                <div className="fixed-cta full">
                                {
                                    this.state.cimsallow && addedDrugsList?.length > 0
                                        ?
                                        <Button
                                            type="submit"
                                            className="btn btn-outline-secondary px-4 text-uppercase template-save mr-2"
                                            onClick={() => this.showModalInteraction()}>
                                            <i className="icon-medinfo drug_info mr-2" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}></i>
                                            VIEW ALL INTERACTIONS  {this.state.spin_loading ? LOADER : ''}
                                        </Button>
                                        : null
                                    }
                                    <Button
                                        type="submit"
                                        className="btn btn-primary px-5 text-uppercase template-save ml-3"
                                        disabled={this.state.disableConfirmBtn || this.state.spin_loading}
                                        onClick={() =>
                                            this.orderSetDetailsUpdate()
                                        }
                                    >
                                        Save Order Set {this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* For Modal Instruction  */}
                {this.state.showInstructionModal ? (
                    <Modal
                        title={this.state.actionText + " Instructions"}
                        visible={this.state.showInstructionModal}
                        onCancel={(e) =>
                            this.handleModalPopup(e, "showInstructionModal")
                        }
                        footer={false}
                    >
                        {this.state.showInstructionModal ? (
                            <InstructionsForm
                                actionText={this.state.actionText}
                                updateData={this.updateInstructionsData}
                                addedInstructionsData={
                                    this.state.instructionsData
                                }
                                handleModalPopup={this.handleModalPopup}
                            />
                        ) : null}
                    </Modal>
                ) : null}

                {/* For Modal Supporting Material */}
                {this.state.showSupportingModal ?
                    <Modal
                        title={false}
                        visible={this.state.showSupportingModal}
                        onCancel={(e) => this.handleModalPopup(e, 'showSupportingModal')}
                        width="850px"
                        footer={false}
                    // bodyStyle={{ padding: "10px 20px" }}
                    >
                        <form className="template-form" autoComplete="off" encType="multipart/form-data">
                            <div className="pb-5">
                                {this.state.showSupportingModal ?
                                    <SupportingMaterial
                                        formType={"order-set"}
                                        orderSetId={this.state.orderSetId}
                                        actionText={this.state.actionText}
                                        setMaterialData={(data) => this.getMaterialData(data)}
                                        allSupportingMaterial={this.state.allSupportingMaterial}
                                        supportingMaterial={this.state.supportingMaterial}
                                        isEdit={this.state.isEdit}
                                    /> : null}
                            </div>
                            <div className="modal-footer pb-0">
                                <div className="col-12 text-center instructions_cta">
                                    <button type="button" className="btn btn-outline-secondary px-4 mr-2" onClick={(e) => this.handleModalPopup(e, 'showSupportingModal')}>CANCEL</button>
                                    <div className="btn btn-primary px-4 text-uppercase confirm-instructions"
                                        onClick={this.saveSupportingMaterial}>SAVE</div>
                                </div>
                            </div>
                        </form>
                    </Modal>
                    : null
                }

                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) =>
                        this.handleModalPopup(e, "deleteConfirmModal")
                    }
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">
                            {this.state.deleteMessage}
                        </h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button
                            type="button"
                            className="btn btn-outline-secondary px-5  mr-2"
                            onClick={(e) =>
                                this.handleModalPopup(e, "deleteConfirmModal")
                            }
                        >
                            CANCEL
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={(e) =>
                                this.handleOnItemDelete(
                                    e,
                                    this.state.deleteActionType,
                                    this.state.deleteObjId
                                )
                            }
                        >
                            DELETE
                        </button>
                    </div>
                </Modal>

                <Modal
                    className="templateError"
                    id="alertMessage"
                    title={this.state.alertMessage}
                    visible={this.state.istemplateErrorVisible}
                    onOk={() => this.handleTemplateErrorOk()}
                    onCancel={() => this.handleTemplateErrorCancel()}
                    footer={[
                        <Button
                            type="button"
                            className="btn btn-outline-primary px-5"
                            onClick={() => this.handleTemplateErrorCancel()}
                        >
                            OK
                        </Button>,
                    ]}
                ></Modal>

                {/* Modal For Hospital Master Error  */}
                <Modal
                    title="Hospital Master Error"
                    visible={this.state.isHospitalHaveMasterVisible}
                    onCancel={() => this.handleHospitalMasterError()}
                    footer={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">
                            Error! Protocols for multiple hospitals selection can only be created if all selected hospitals don't have their own masters. <br /><br />
                            Protocol can't be created for this combination.
                        </h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={() => this.handleHospitalMasterError()}
                        >
                            OK
                        </button>
                    </div>
                </Modal>

                <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
                    title="All Drug Interaction"
                    visible={this.state.isModalAllInteractionVisible}
                    onOk={() => this.handleAllInteractionOk()}
                    onCancel={() => this.handleAllInteractionCancel()}
                    footer={false}
                    style={{ width: 2000 }}
                    zIndex={7776}
                >
                    <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${ALL_CIMS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
                    {/* <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllInteractionCancel(e)}>DON’T PROCEED</button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleAllInteractionOk(e)}>PROCEED TO ORDER</button>
                    </div> */}
                </Modal>
            </React.Fragment>
        );
    }
}

export default saveOrderSet;
